import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ComponentCanDeactivate } from '@app/shared/can-deactivate/component-can-deactivate';


@Injectable()
export class CanDeactivateGuard  {
  canDeactivate(component: ComponentCanDeactivate): boolean {
   
    if(!component.canDeactivate()){

   //   return false;
        if (confirm("You have unsaved changes!")) {
            return true;
        } else {
            return false;
        }
    }
    return true;
  }
}
